import React from "react";
import "./Suspention.css";
const Suspention = () => {
  return (
    <div className="loading-state">
      <div className="loading" />
    </div>
  );
};

export default Suspention;
